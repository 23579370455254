// src/pages/PaymentPage.js

import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import PricingTable from '../components/PricingTable';
import Countdown from 'react-countdown';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PaymentPage(setIsAlert, setAlertMessage, setAlertSeverity) {
  const salePercentage = 70;
  const saleBannerColour = 'bg-yellow-500';


  function getEndOfMonth() {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    return endOfMonth;
  }
  const saleDuration = 'month';
  function getSaleLabel() {
    if (saleDuration === 'month') {
      const now = new Date();
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      return `${monthNames[now.getMonth()]} Sale`;
    } else if (saleDuration === 'week') {
      return '7-day Sale';
    } else if (saleDuration === 'day') {
      return '24-hour Flash Sale';
    } else {
      return 'Sale';
    }
  }
  //181414

  function SaleBanner() {
    return (
      <div className={`${saleBannerColour} text-white font-bold p-5 text-center text-2xl my-4 rounded-[5px] shadow-[0_4px_6px_rgba(0,0,0,0.1)]`}>
        <span>
          {getSaleLabel()} - Save {salePercentage.toString()}%! Hurry up! Ends in{" "}
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>0d 0h 0m 0s</span>;
              } else {
                return (
                  <span>
                    {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
        </span>
      </div>
    );
  }
  return (
    <>
    <div className="min-h-screen py-10 w-screen">
    <SaleBanner/>
    <div className="flex flex-col px-8 lg:px-36">
      <h1 className="text-4xl font-bold text-center text-white mb-12">Our Pricing Plans</h1>
          <p className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
            Charged monthly. Cancel any time.
          </p>
          <Countdown
            date={getEndOfMonth()}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              if (completed) {
                return <span>{getSaleLabel()} has ended.</span>;
              } else {
                return (
                  <span className="text-left text-sm mx-6 mt-4 font-bold tracking-tight text-white/80">
                    {getSaleLabel()} ends in {days}d {hours}h {minutes}m {seconds}s
                  </span>
                );
              }
            }}
          />
      <PricingTable 
        className="mt-5" 
        setIsAlert={setIsAlert} 
        setAlertMessage={setAlertMessage} 
        setAlertSeverity={setAlertSeverity} 
        saleBannerColour={saleBannerColour}
        salePercentage={salePercentage}
        saleDuration={saleDuration}
        />
      </div>
    </div>
    </>
  );
}

export default PaymentPage;
