// src/pages/ScrapePage.js
import React, { useState } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';



function ScrapePage() {
  // State variables
  const [url, setUrl] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataPoints, setDataPoints] = useState('');

  // const serverUrl = 'http://localhost:5001/api/scrape';
  const serverUrl = `${process.env.REACT_APP_SERVER_URL}/api/scrape`;

  const { authState } = useContext(AuthContext);

  if (!authState?.user?.customer?.isPaidUser) {
    return (
      <div className="text-white">
        <h2>Access Denied</h2>
        <p>You need to purchase access to use this feature.</p>
        <Link to="/plans">Click here to purchase access</Link>
      </div>
    );
  }

  // Handle form submission
  const downloadData = () => {
    const blob = new Blob([data.join('\n')], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'scraped_data.csv');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // make API call to backend
    try{
      //add bearer token

    const response = await axios.post(serverUrl, {
      url,
      dataPoints,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }
    }
  );
    setData(response.data.data);
    } catch (error) {
      console.error('Error:', error);
    }
    setLoading(false);

  };
  return (
    <div className="w-full items-center flex flex-col">
      {/* Form container */}
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-lg bg-gray-800 rounded-lg shadow-sm p-8"
      >
        <div className="mb-6">
          <label className="block text-gray-200 text-lg font-semibold mb-2">
            Website URL
          </label>
          <input
            type="text"
            placeholder="https://example.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full p-3 border rounded-lg text-gray-200 bg-gray-50 dark:bg-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-200 text-lg font-semibold mb-2">
            List of Data Points
          </label>
          <textarea
            placeholder="Enter each data point on a new line"
            value={dataPoints}
            onChange={(e) => setDataPoints(e.target.value)}
            className="w-full p-3 border rounded-lg text-gray-800 dark:text-gray-200 bg-gray-50 dark:bg-gray-800 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            rows="5"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-3 rounded-lg shadow-md transition-all duration-300"
        >
          {loading ? 'Scraping...' : 'Scrape'}
        </button>
      </form>

      {/* Loading state */}
      {loading && (
        <div className="mt-10 flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-opacity-75"></div>
          <p className="text-lg text-gray-700 dark:text-gray-200 mt-4">Scraping in progress...</p>
        </div>
      )}

      {/* Results container */}
      {data && !loading && (
        <div className="w-full max-w-lg bg-white dark:bg-gray-900 rounded-lg shadow-lg p-8 mt-10">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">Results:</h2>
          <pre className="text-gray-800 dark:text-gray-200 whitespace-pre-wrap bg-gray-100 dark:bg-gray-800 p-4 rounded-lg">
            {data.length ? JSON.stringify(data, null, 2) : 'No data found'}
          </pre>
          {data.length > 0 && (
            <button
              onClick={downloadData}
              className="w-full mt-6 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 rounded-lg shadow-md transition-all duration-300"
            >
              Download Data
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default ScrapePage;
