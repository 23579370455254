import { useEffect, useState } from 'react';

function usePrices() {
  const [prices, setPrices] = useState([]);

  const url = `${process.env.REACT_APP_SERVER_URL}/api/payment`
  // const url = 'http://localhost:5001/api/payment';
  useEffect(() => {
    async function fetchPrices() {
      try {
        const response = await fetch(`${url}/get-prices`);
        const data = await response.json();
        setPrices(data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    }

    fetchPrices();
    
  }, []);

  return prices;
}

export default usePrices;
