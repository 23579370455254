import React, { useState } from 'react';
import PricingPlan from './PricingPlan';
import useUserCurrency from '../hooks/useUserCurrency';
import usePrices from '../hooks/usePrices';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PricingTable({
  setIsAlert, 
  setAlertMessage, 
  setAlertSeverity, 
  defaultPricingOption,
  saleBannerColour,
  salePercentage,
  saleDuration,
}) {
  const currency = useUserCurrency();
  let isSubscriptions = false;

  const prices = usePrices(currency, isSubscriptions);

  let [buttonName, setButtonName] = useState('Purchase credits');

  let mode = 'payment'; // Change to 'payment' for one-time payments
  // const url = 'http://localhost:5001/api/payment';
  const url = `${process.env.REACT_APP_SERVER_URL}/api/payment`



  const filterPrices = (price) => {
    if (isSubscriptions && ((price.product.name === "Caterpillar") || (price.product.name === "Ladybug") || (price.product.name === "Spider"))){
      return price.recurring;
    } else if (!isSubscriptions && ((price.product.name === "Caterpillar") || (price.product.name === "Ladybug") || (price.product.name === "Spider"))){
      return !price.recurring;
    }
  }


  const handleSelectPlan = async (priceId, priceRecurring, product, priceAmount) => {
    try {
      if (!priceRecurring){
        mode = 'payment';
      }

      if (!localStorage.getItem('token')) {
        setIsAlert(true);
        setAlertMessage('Please sign in to continue');
        setAlertSeverity('error');
        window.location.href = '/signin';
        return;
      }


      
      const response = await fetch(`${url}/create-checkout-session`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token') },
        body: JSON.stringify({ priceId, mode, product: product.name }),
      });
      const data = await response.json();

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.id  });
    } catch (error) {
      console.error('Error during checkout:', error);
      // Display error message to user
    }
  };

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center">
      {prices.length > 0 ? (
        prices.filter((price) => filterPrices(price)).sort((a, b)=> parseFloat(a.unit_amount) - parseFloat(b.unit_amount)).map((price) => (
          <PricingPlan
            key={price.id}
            name={price.product.name}
            price={price.currency_options[currency.toLowerCase()]?.unit_amount || price.unit_amount}
            currency={currency.toUpperCase()}
            features={price.product.features || []}
            onSelect={() => handleSelectPlan(price.id, price.recurring, price.product, price.unit_amount)}
            isSubscriptions={isSubscriptions}
            mostPopular={price.product.name === 'Ladybug'}
            salePercentage={salePercentage}
            buttonName={buttonName}
            setButtonName={setButtonName}
          />
        ))
      ) : (
        <p className="text-white text-xl">Loading prices...</p>
      )}
    </div>
  );
}

export default PricingTable;
