import React from 'react';

function PricingPlan({ 
    name, 
    price, 
    currency, 
    features, 
    onSelect, 
    mostPopular, 
    isSubscriptions,
    salePercentage,
    buttonName,
    setButtonName,
   }) {
  // Calculate the original price
  let originalPrice = price * 2;

  if (salePercentage) {
    originalPrice = price / ((100 - salePercentage) / 100);
  }

  // Format the prices for display
  const formattedOriginalPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(Math.round(originalPrice / 100));

  const formattedSalePrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  }).format(price / 100);

  return (
    <div className="relative max-w-sm rounded-lg overflow-hidden shadow-lg bg-gray-800 m-4 border border-gray-700">
      {mostPopular && (
        <div className="absolute top-0 right-0 bg-indigo-500 text-white px-2 py-1 text-xs font-semibold uppercase tracking-wide rounded-bl-lg">
          Most Popular
        </div>
      )}
      <div className="px-6 py-8">
        <h2 className="font-semibold text-2xl text-white mb-4">{name}</h2>
        {/* Price Section */}
        <div className="mb-4 flex flex-col items-baseline">
          {/* Original Price with Strikethrough */}
          <span className="text-gray-400 text-xl line-through mr-2">
            {formattedOriginalPrice}
          </span>
          {/* Sale Price */}
          <span className="text-white text-4xl font-bold">
            {formattedSalePrice}
          </span>
          {isSubscriptions && <span className="text-base text-gray-300 font-medium"> / month</span>}
        </div>
        <ul className="text-gray-300 mb-6 space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center">
              <svg
                className="w-5 h-5 text-green-400 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                strokeWidth={5}
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5 13l4 4L19 7"
                />
              </svg>
              <span>{feature.name}</span>
            </li>
          ))}
        </ul>
        <button
          onClick={onSelect}
          className="w-full bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-3 px-4 rounded-lg transition duration-300"
        >
          {buttonName ? buttonName.includes('placeholder') ? buttonName.replace('placeholder', name) : buttonName : "Select Plan"}

        </button>
      </div>
    </div>
  );
}

export default PricingPlan;

