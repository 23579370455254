// src/components/Navbar.js

import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const pages = ['Plans', 'Contact'];

  const { authState, setAuthState } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthState({ token: null, user: null, loading: false });
    navigate('/home');
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Logo */}
        <Typography
          variant="h6"
          noWrap
          component={Link}
          to="/home"
          sx={{
            mr: 2,
            display: 'flex',
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          {/* Replace with your logo image if you have one */}
          LOGO
        </Typography>

        {/* Desktop Menu */}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              key={page}
              component={Link}
              to={`/${page.toLowerCase()}`}
              onClick={handleCloseNavMenu}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              {page}
            </Button>
          ))}
        </Box>

        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Sign In and Sign Up Buttons */}
        {authState.token ?
        ( <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            component={Link}
            to="/dashboard"
            sx={{ my: 2, color: 'white' }}
          >
            Dashboard
          </Button>
          <Button
            component={Link}
            to="/home"
            onClick={handleLogout}
            sx={{ my: 2, color: 'white' }}
          >
            Sign Out
          </Button>
          </Box>
          )
        :
        (<Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Button
            component={Link}
            to="/sign-in"
            sx={{ my: 2, color: 'white' }}
          >
            Sign In
          </Button>
          <Button
            component={Link}
            to="/sign-up"
            variant="outlined"
            sx={{ my: 2, color: 'white', borderColor: 'white', ml: 1 }}
          >
            Sign Up
          </Button>
        </Box>)
        }

        {/* Mobile Menu Icon */}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  component={Link}
                  to={`/${page.toLowerCase()}`}
                  sx={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {page}
                </Typography>
              </MenuItem>
            ))}

            {authState.token ?
            (
              [
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                textAlign="center"
                component={Link}
                to="/dashboard"
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                Dashboard
              </Typography>
            </MenuItem>
            ,
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                textAlign="center"
                component={Link}
                to="/home"
                onClick={handleLogout}
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                Sign Out
            </Typography>
            </MenuItem>
            ]
          )
            :
            ([
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                textAlign="center"
                component={Link}
                to="/sign-in"
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                Sign In
              </Typography>
            </MenuItem>
            ,
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography
                textAlign="center"
                component={Link}
                to="/sign-up"
                sx={{ textDecoration: 'none', color: 'inherit' }}
              >
                Sign Up
              </Typography>
            </MenuItem>
            ])
            }
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
