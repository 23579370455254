// src/components/ProfileUpdateForm.js

import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';

function ProfileUpdateForm({ user }) {
  const [formData, setFormData] = useState({
    name: user.name,
    // Add other fields as needed
  });
  // const url = 'http://localhost:5001/api/auth/update';
  const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/update`;


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send update request to backend
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        url,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // Handle success (e.g., update auth state)
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4}}>
      <TextField
        name="name"
        label="Name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        className="bg-gray-100"
      />
      {/* Add other fields as needed */}
      <Button variant="contained" type="submit">
        Update Profile
      </Button>
    </Box>
  );
}

export default ProfileUpdateForm;
