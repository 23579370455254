// src/pages/SignIn.js

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid2,
  Link,
  Avatar,
  Box,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignIn() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  // const url = 'http://localhost:5001/api/auth/login';
  const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/login`;
  const [errors, setErrors] = useState('');

  const { email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');

    try {
      const response = await axios.post(url, formData);
      if (response.data.success) {
        // Save the token to localStorage or context
        localStorage.setItem('token', response.data.token);
        console.log('Logged in successfully');
        console.log(response.data.token);
        navigate('/home'); // Redirect to home or dashboard
        window.location.reload();
      }
    } catch (error) {
      setErrors(error.response.data.message || 'An error occurred.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography color="white" component="h1" variant="h5">
          Sign In
        </Typography>
        {errors && (
          <Typography color="error" variant="body2">
            {errors}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            name="email"
            type="email"
            required
            fullWidth
            label="Email Address"
            value={email}
            onChange={handleChange}
            margin="normal"
            className="bg-gray-100"
          />
          <TextField
            name="password"
            type="password"
            required
            fullWidth
            label="Password"
            value={password}
            onChange={handleChange}
            margin="normal"
            className="bg-gray-100"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid2 container>
            <Grid2 xs={12}>
              {/* You can add Forgot Password link here */}
            </Grid2>
            <Grid2>
              <Link href="/sign-up" variant="body2">
                Don't have an account? Sign Up
              </Link>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
