// src/pages/SignUp.js

import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid2,
  Link,
  Avatar,
  Box,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function SignUp() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  // const url = 'http://localhost:5001/api/auth/register';
  const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/register`;
  const [errors, setErrors] = useState('');

  const { name, email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors('');

    try {
      const response = await axios.post(url, formData);
      if (response.data.success) {
        navigate('/sign-in');
      }
    } catch (error) {
      setErrors(error.response.data.message || 'An error occurred.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" color='white' variant="h5">
          Sign Up
        </Typography>
        {errors && (
          <Typography color="error" variant="body2">
            {errors}
          </Typography>
        )}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid2 container className="justify-center" spacing={2}>
            <Grid2 item className="w-full" xs={12}>
              <TextField
                name="name"
                required
                fullWidth
                label="Name"
                value={name}
                onChange={handleChange}
                className="bg-gray-100"
              />
            </Grid2>
            <Grid2 item className="w-full" xs={12}>
              <TextField
                name="email"
                type="email"
                required
                fullWidth
                label="Email Address"
                value={email}
                onChange={handleChange}
                className="bg-gray-100"
              />
            </Grid2>
            <Grid2 item className="w-full" xs={12}>
              <TextField
                name="password"
                type="password"
                required
                fullWidth
                label="Password"
                value={password}
                onChange={handleChange}
                className="bg-gray-100"
              />
            </Grid2>
          </Grid2>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid2 container justifyContent="flex-end">
            <Grid2 item>
              <Link href="/sign-in" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
    </Container>
  );
}

export default SignUp;
