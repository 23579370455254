// src/pages/Dashboard.js

import React, { useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Avatar,
  Button,
} from '@mui/material';
import { AuthContext } from '../context/AuthContext';
import ProfileUpdateForm from '../components/ProfileUpdateForm';
import { useNavigate } from 'react-router-dom';




function Dashboard() {
  const { authState, setAuthState } = useContext(AuthContext);
  const { user } = authState;
  const navigate = useNavigate();


  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuthState({ token: null, user: null, loading: false });
    navigate('/home');
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          {user.name.charAt(0)}
        </Avatar>
        <Typography component="h1" className="text-gray-100" variant="h4">
          Welcome, {user.name}
        </Typography>
        <Typography variant="h6" className="text-gray-100" gutterBottom>
          Email: {user.email}
        </Typography>
        <Typography variant="h6" className="text-gray-100" gutterBottom>
          Credits: {user.creditsUsed} / {user.creditLimit}
        </Typography>
        {/* Add more user info as needed */}

      </Box>
      <ProfileUpdateForm user={user} />;

      <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          sx={{ mt: 2 }}
        >
          Logout
      </Button>

    </Container>
  );
}

export default Dashboard;
