// src/pages/CrawlPage.js
import React, { useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { ClipboardIcon } from '@heroicons/react/24/outline';



function CrawlPage() {
  const [url, setUrl] = useState('');
  const [includeSubpages, setIncludeSubpages] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [baseUrl, setBaseUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [copiedAll, setCopiedAll] = useState(false);

  const serverUrl = `${process.env.REACT_APP_SERVER_URL}`;
  // const serverUrl = 'http://localhost:5001';
  const handleCopyAll = () => {
    navigator.clipboard.writeText(JSON.stringify(data)).then(() => {
      setCopiedAll(true);
      setTimeout(() => setCopiedAll(false), 2000);
    });
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setData(null);
    setError(null);
    setBaseUrl(new URL(url).origin);


    try {
      const response = await axios.get(`${serverUrl}/api/crawl?url=${url}&includeSubpages=${includeSubpages}`);
      setData(response.data);
    } catch (error) {
      console.error('Error collecting data:', error);
      setError('An error occurred while collecting data. Please try again.');
    }
    setLoading(false);
  };
  const transformLinkUri = (href) => {
    if (href.startsWith('http') || href.startsWith('mailto:')) {
      return href;
    }
    return baseUrl + href;
  };
  
  const PageContent = ({ page }) => {
    const [showSubpages, setShowSubpages] = useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(page.markdownContent).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    };

    return (
      <div className="w-full max-w-lg">
        <h3 className="text-lg font-bold mb-2 text-gray-800 dark:text-gray-100">
          {page.url}
        </h3>
        <button
          onClick={handleCopy}
          className="text-blue-500 hover:underline focus:outline-none ml-4"
        >
          <ClipboardIcon className="h-6 w-6" />
        </button>
        {copied && <span className="ml-2 text-green-500">Copied This Page!</span>}
        <div className="prose dark:prose-invert max-w-none">
          <ReactMarkdown urlTransform={transformLinkUri}>{page.markdownContent}</ReactMarkdown>
        </div>
        {page.subpages && page.subpages.length > 0 && (
          <div className="mt-4">
            <button
              onClick={() => setShowSubpages(!showSubpages)}
              className="text-blue-500 hover:underline focus:outline-none"
            >
              {showSubpages ? 'Hide Subpages' : 'Show Subpages'}
            </button>
            {showSubpages && (
              <div className="ml-4 mt-4 border-l pl-4">
                {page.subpages.map((subpage, index) => (
                  <PageContent key={index} page={subpage} />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col items-center p-4 min-h-screen bg-gray-50 dark:bg-gray-900">
      <form
        onSubmit={handleSubmit}
        className="mt-4 w-full max-w-md bg-white dark:bg-gray-800 rounded-lg shadow-md p-6"
      >
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-200 text-sm font-bold mb-2">
            Website URL
          </label>
          <input
            type="text"
            placeholder="https://example.com"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          />
        </div>
        <div className="mb-4 flex items-center">
          <input
            type="checkbox"
            checked={includeSubpages}
            onChange={(e) => setIncludeSubpages(e.target.checked)}
            className="mr-2 leading-tight"
          />
          <label className="text-gray-700 dark:text-gray-200">
            Include Subpages
          </label>
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? 'Collecting...' : 'Collect Data'}
        </button>
      </form>

      {loading && (
        <div className="mt-6 flex flex-col items-center">
          <svg
            className="animate-spin h-8 w-8 text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25 stroke-current"
              cx="12"
              cy="12"
              r="10"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75 fill-current"
              d="M4 12a8 8 0 018-8v8H4z"
            ></path>
          </svg>
          <p className="text-gray-700 dark:text-gray-200 mt-2">
            Collecting data...
          </p>
        </div>
      )}

      {error && (
        <div
          className="w-full max-w-md bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-6"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      {data && (
        <div className="w-full max-w-3xl bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mt-6 relative">
        <button
              onClick={handleCopyAll}
              //align button to the right
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 focus:outline-none"
              aria-label="Copy All Markdown"
              title="Copy All Markdown"
            >
              <ClipboardIcon className="h-6 w-6" />
            </button>
            {copiedAll && (
              <span className="absolute top-2 right-10 text-green-500">
                Copied All Data!
              </span>
            )}
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-gray-100">
            LLM-Ready Data:
          </h2>
          <PageContent page={data} />
        </div>
      )}
    </div>
  );
}

export default CrawlPage;
