// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem('token'),
    user: null,
    loading: true,
  });
  const url = `${process.env.REACT_APP_SERVER_URL}/api/auth/user`;
  // const url = 'http://localhost:5001/api/auth/user';

  useEffect(() => {
    const verifyToken = async () => {
      if (authState.token) {
        try {
          const response = await axios.get(url, {
            headers: { Authorization: `Bearer ${authState.token}` },
          });
          setAuthState({ ...authState, user: response.data.user, loading: false });
        } catch (error) {
          console.error('Invalid token:', error);
          localStorage.removeItem('token');
          setAuthState({ token: null, user: null, loading: false });
        }
      } else {
        setAuthState({ ...authState, loading: false });
      }
    };

    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};
