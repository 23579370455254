// src/components/PrivateRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (authState.loading) {
    // You can return a loading indicator here
    return <div>Loading...</div>;
  }

  return authState.token ? children : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
