// src/components/Tabs.js
import React, { useState } from 'react';
import ScrapePage from '../pages/ScrapePage';
import CrawlPage from '../pages/CrawlPage';

function Tabs() {
  const [selected, setSelected] = useState('scrape');
  return (
    <div>
      <div className="flex flex-col items-center p-6 bg-slate-950 min-h-screen">
      { selected === 'scrape' && <h1 className="text-2xl font-semibold my-8 text-gray-100">
          Scrape and Format Data
        </h1>}
      { selected === 'crawl' && <h1 className="text-2xl font-semibold my-8 text-gray-100">
          Collect LLM-Ready Data
        </h1>}
      <div className="flex flex-row justify-center items-center bg-gray-200 dark:bg-black rounded-lg  mb-4">

          <button
            onClick={() => setSelected('scrape')}
            className={`${selected === 'scrape' ? 'bg-blue-500' : 'bg-black' } 
            p-2 
            flex 
            justify-center 
            items-center 
            align-middle 
            text-center 
            w-full 
            min-h-full
            px-4 text-gray-700 dark:text-gray-200 rounded-lg`}
          >
            Scrape
          </button>
          <button
            className={`
              ${selected === 'crawl' ? 'bg-blue-500' : 'bg-black' } 
              p-2 
              flex 
              items-center
              justify-center 
              align-middle 
              w-full 
              min-h-full 
              text-center 
              px-4 
              text-gray-700 
              dark:text-gray-200 
              rounded-lg
              `}
            onClick={() => setSelected('crawl')}
          >
            Collect
          </button>
      </div>
      {selected === 'scrape' && (<ScrapePage />)}
      {selected === 'crawl' && (<CrawlPage />)}
    </div>
    </div>
  );
}

export default Tabs;
