// src/pages/PaymentCancelled.js

import React from 'react';

function PaymentCancelled() {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Payment Cancelled</h2>
      <p>You have cancelled the payment. If this was a mistake, please try again.</p>
    </div>
  );
}

export default PaymentCancelled;
