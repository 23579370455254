// src/App.js
import React, { useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Tabs from './components/Tabs';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import PaymentPage from './pages/PaymentPage';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancelled from './pages/PaymentCancelled';
import Alert from '@mui/material/Alert';
import { isMobile } from 'react-device-detect';



function App() {
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <AuthProvider>
        <Navbar />
        {isAlert && <>{ isMobile ? <Alert style={{paddingTop: 60}} severity={alertSeverity} onClose={() => setIsAlert(false)}>{alertMessage}</Alert> : <Alert style={{paddingTop: 60, marginLeft: 70}} severity={alertSeverity} onClose={() => setIsAlert(false)}>{alertMessage}</Alert>} </>}
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Tabs />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/plans" element={<PrivateRoute><PaymentPage setAlertMessage={setAlertMessage} setAlertSeverity={setAlertSeverity} setIsAlert={setIsAlert}  /></PrivateRoute>} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          <Route path="/payment-cancelled" element={<PaymentCancelled />} />
                  {/* Protected Route */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </div>

  );
}
export default App;
