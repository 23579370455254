// src/pages/PaymentSuccess.js

import React from 'react';
import { useSearchParams } from 'react-router-dom';

function PaymentSuccess() {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  // Optionally, you can fetch the session details from Stripe using the session ID

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h2>Payment Successful!</h2>
      <p>Your payment was successful. Thank you for your purchase!</p>
      <p>Session ID: {sessionId}</p>
    </div>
  );
}

export default PaymentSuccess;
